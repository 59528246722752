<template>
  <div id="vehicles">
    <div class="page-title px-4 mb-4">
      <h2>
        <span class="me-2">Vehicle Management</span>
      </h2>
    </div>
    <v-divider class="mt-4 mb-10"></v-divider>

    <v-tabs v-model="currentTab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.icon">
        <v-icon class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>
          {{ tab.title }}
        </span>
      </v-tab>
    </v-tabs>

    <v-card>
      <v-row class="pt-4 pb-0 ma-0">
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="sqVehicleID"
            label="Vehicle ID"
            outlined
            hide-details
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqVehicleID')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="sqDeviceID"
            label="Device ID"
            outlined
            hide-details
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqDeviceID')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            v-model="sqIsEnabled"
            :items="boolItems"
            item-text="text"
            item-value="value"
            label="Device enabled"
            outlined
            hide-details
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqIsEnabled')"
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            v-model="sqVolumeOverride"
            :items="boolItems"
            item-text="text"
            item-value="value"
            label="Override volume"
            outlined
            hide-details
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqVolumeOverride')"
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            v-model="sqOnTrip"
            :items="boolItems"
            item-text="text"
            item-value="value"
            label="On Trip"
            outlined
            hide-details
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqOnTrip')"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-tabs-items v-model="currentTab">
        <v-tab-item>
          <v-data-table
            :headers="headers"
            :items="filteredVehicles"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :custom-sort="customSort"
            class="font-weight-semibold text--primary"
            :page.sync="page"
            :must-sort="true"
          >
            <template v-slot:item.vehicle_id="{ item }">
              <router-link
                :to="{ name: 'next-stop-vehicle-config', params: { id: item.vehicle_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.vehicle_id }}</span>
                  </div>
                </div>
              </router-link>
            </template>

            <template v-slot:item.device_id="{ item }">
              <router-link
                :to="{ name: 'next-stop-vehicle-config', params: { id: item.vehicle_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.status.device_id }}</span>
                  </div>
                </div>
              </router-link>
            </template>

            <template v-slot:item.is_enabled="{ item }">
              <router-link
                :to="{ name: 'next-stop-vehicle-config', params: { id: item.vehicle_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span :class="['font-weight-semibold', item.config.is_enabled ? 'success--text' : 'error--text']">
                      {{ item.config.is_enabled ? "Yes" : "No" }}
                    </span>
                  </div>
                </div>
              </router-link>
            </template>

            <template v-slot:item.override_volume="{ item }">
              <router-link
                :to="{ name: 'next-stop-vehicle-config', params: { id: item.vehicle_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span
                      :class="['font-weight-semibold', item.config.override_volume ? 'success--text' : 'error--text']"
                    >
                      {{ item.config.override_volume ? "Yes" : "No" }}
                    </span>
                  </div>
                </div>
              </router-link>
            </template>

            <template v-slot:item.device_status="{ item }">
              <router-link
                :to="{ name: 'next-stop-vehicle-config', params: { id: item.vehicle_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{
                      item.status.status ? item.status.status : "unknown"
                    }}</span>
                  </div>
                </div>
              </router-link>
            </template>

            <template v-slot:item.peak_volume="{ item }">
              <router-link
                :to="{ name: 'next-stop-vehicle-config', params: { id: item.vehicle_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{
                      item.config.override_volume ? item.config.peak_volume : settings.peak_volume
                    }}</span>
                  </div>
                </div>
              </router-link>
            </template>

            <template v-slot:item.offpeak_volume="{ item }">
              <router-link
                :to="{ name: 'next-stop-vehicle-config', params: { id: item.vehicle_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{
                      item.config.override_volume ? item.config.offpeak_volume : settings.offpeak_volume
                    }}</span>
                  </div>
                </div>
              </router-link>
            </template>

            <template v-slot:item.quiet_volume="{ item }">
              <router-link
                :to="{ name: 'next-stop-vehicle-config', params: { id: item.vehicle_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{
                      item.config.override_volume ? item.config.quiet_volume : settings.quiet_volume
                    }}</span>
                  </div>
                </div>
              </router-link>
            </template>

            <template v-slot:item.on_trip="{ item }">
              <router-link
                :to="{ name: 'next-stop-vehicle-config', params: { id: item.vehicle_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span :class="['font-weight-semibold', item.status.trip.trip_id ? 'success--text' : 'error--text']"
                      >{{ item.status.trip.trip_id ? "Yes" : "No" }}
                    </span>
                    <span v-if="item.status.trip.trip_id" class="font-weight-semibold text--secondary text-xs">
                      {{ item.status.trip.trip_id }}
                    </span>
                  </div>
                </div>
              </router-link>
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn icon :to="{ name: 'next-stop-vehicle-config', params: { id: item.vehicle_id } }">
                <v-icon color="primary">
                  {{ icons.mdiSquareEditOutline }}
                </v-icon>
              </v-btn>
              <v-btn v-if="vehicleHasPosition(item)" icon @click="centerVehicle(item)">
                <v-icon color="primary">
                  {{ icons.mdiMapMarker }}
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <MglMap
            ref="map"
            :access-token="$config.mapbox.accessToken"
            :track-resize="true"
            :touch-zoom-rotate="true"
            :scroll-zoom="true"
            :attribution-control="false"
            :map-style="$config.mapbox.style"
            :zoom="$config.mapbox.zoom"
            :center="$config.mapbox.center"
          >
            <template v-for="vehicle in filteredVehicles">
              <MglMarker
                v-if="vehicleHasPosition(vehicle)"
                :key="`vehicle-${vehicle.vehicle_id}`"
                :ref="`vehicle-marker-${vehicle.vehicle_id}`"
                :coordinates="[vehicle.status.position.longitude, vehicle.status.position.latitude]"
              >
                <MglPopup
                  ref="vehicle-popup"
                  @open="vehiclePopupShowedID = vehicle.vehicle_id"
                  @close="onVehiclePopupClose(vehicle)"
                >
                  <VehiclePopup :vehicle-data="vehicle" />
                </MglPopup>

                <vehicle-marker
                  slot="marker"
                  :route="vehicle.route"
                  :position="vehicle.status.position"
                ></vehicle-marker>
              </MglMarker>
            </template>
          </MglMap>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mdiBus, mdiMapMarker, mdiSquareEditOutline, mdiMap } from "@mdi/js"
import { mapState } from "vuex"
import { MglMap, MglMarker, MglPopup } from "vue-mapbox"
import useAppConfig from "@core/@app-config/useAppConfig"
import VehicleMarker from "@/components/vehicle/Marker.vue"
import VehiclePopup from "./components/VehiclePopup.vue"
import store from "@/store"
import "mapbox-gl/dist/mapbox-gl.css"
import urlHelpers from "@/mixins/urlHelpers"

export default {
  name: "VehicleList",
  metaInfo: {
    title: "Vehicles",
  },
  components: {
    MglMap,
    MglMarker,
    MglPopup,
    VehicleMarker,
    VehiclePopup,
  },
  mixins: [urlHelpers],
  setup() {
    const { refreshIntervalVehicles } = useAppConfig()

    const liveUpdate = setInterval(async () => {
      await store.dispatch("getVehicles")
    }, refreshIntervalVehicles * 1000)

    return {
      liveUpdate,
      icons: {
        mdiBus,
        mdiMapMarker,
        mdiSquareEditOutline,
      },
    }
  },

  data() {
    return {
      sqVehicleID: "",
      sqDeviceID: "",
      sqVolumeOverride: "",
      sqIsEnabled: "",
      sqOnTrip: "",
      vehiclePopupShowedID: false,
      showTable: false,
      currentTab: "",
      selectedMarker: null,
      selectedRoutes: [],
      tabs: [
        { title: "Vehicles", icon: mdiBus },
        { title: "Map", icon: mdiMap },
      ],
      sortBy: "vehicle_id",
      sortDesc: false,
      headers: [
        { text: "Vehicle ID", value: "vehicle_id" },
        { text: "Device ID", value: "device_id" },
        { text: "Device Enabled", value: "is_enabled", sortable: false },
        { text: "Device Status", value: "device_status", sortable: true },
        { text: "Peak Volume", value: "peak_volume" },
        { text: "Off Peak Volume", value: "offpeak_volume" },
        { text: "Quiet Volume", value: "quiet_volume" },
        { text: "On Trip", value: "on_trip", sortable: false },
        { text: "Override Volume", value: "override_volume", sortable: false },
        { text: "", value: "action", sortable: false },
      ],
      boolItems: [
        {
          text: "",
          value: "",
        },
        {
          text: "Yes",
          value: 1,
        },
        {
          text: "No",
          value: 0,
        },
      ],
    }
  },

  computed: {
    ...mapState({
      routes: state => state.gtfs.routes,
      vehicles: state => state.gtfs.vehicles,
      settings: state => state.settings,
    }),
    processedRoutes() {
      const routes = JSON.parse(JSON.stringify(this.routes))

      return routes.map(route => ({
        text: `${route.route_short_name} - ${route.route_long_name}`,
        value: route.route_id,
      }))
    },
    filteredVehicles() {
      return this.vehicles.filter(vehicle => {
        let include = true

        if (include && this.sqVehicleID.length) {
          include = vehicle.vehicle_id.toLowerCase().includes(this.sqVehicleID.toLowerCase())
        }

        if (include && this.sqDeviceID.length) {
          include = vehicle.status.device_id.toLowerCase().includes(this.sqDeviceID.toLowerCase())
        }

        if (include && this.sqVolumeOverride !== "") {
          include = !!vehicle.config.override_volume === !!this.sqVolumeOverride
        }

        if (include && this.sqIsEnabled !== "") {
          include = !!vehicle.config.is_enabled === !!this.sqIsEnabled
        }

        if (include && this.sqOnTrip !== "") {
          include = !!vehicle.status.trip.trip_id === !!this.sqOnTrip
        }

        return include
      })
    },
  },

  beforeDestroy() {
    clearInterval(this.liveUpdate)
  },

  methods: {
    refreshList() {
      store.dispatch("getVehicles")
    },
    onVehiclePopupClose(vehicle) {
      // keep the vehicle ID in case of vehicle-to-vehicle click
      if (this.vehiclePopupShowedID === vehicle.vehicle_id) {
        this.vehiclePopupShowedID = null
      }
    },
    centerVehicle(vehicle) {
      const { map } = this.$refs.map

      this.$refs["vehicle-popup"].forEach(popup => popup.remove())

      const marker = this.$refs[`vehicle-marker-${vehicle.vehicle_id}`][0]

      if (marker) {
        map.flyTo({
          center: [vehicle.status.position.latitude, vehicle.status.position.latitude],
          essential: true,
        })
      }

      this.selectedMarker = marker
      this.showTable = false

      marker.togglePopup()
    },
    vehicleHasPosition(vehicle) {
      return !!vehicle.status?.position?.longitude
    },
    customSort(items, sortBy, sortDesc) {
      const sortByField = sortBy[0] ?? ""
      const sortDescending = sortDesc[0] ?? false

      items.sort((a, b) => {
        let sortA = a[sortByField]
        let sortB = b[sortByField]

        // Special handling of field sorting when required
        switch (sortByField) {
          case "device_id": {
            sortA = a.status.device_id
            sortB = b.status.device_id

            break
          }
          case "device_status": {
            sortA = a.status.status ? a.status.status : "unknown"
            sortB = b.status.status ? b.status.status : "unknown"

            break
          }
          case "peak_volume": {
            sortA = a.config.override_volume ? a.config.peak_volume : this.settings.peak_volume
            sortB = b.config.override_volume ? b.config.peak_volume : this.settings.peak_volume

            break
          }
          case "offpeak_volume": {
            sortA = a.config.override_volume ? a.config.offpeak_volume : this.settings.offpeak_volume
            sortB = b.config.override_volume ? b.config.offpeak_volume : this.settings.offpeak_volume

            break
          }
          case "quiet_volume": {
            sortA = a.config.override_volume ? a.config.quiet_volume : this.settings.quiet_volume
            sortB = b.config.override_volume ? b.config.quiet_volume : this.settings.quiet_volume

            break
          }
          default:
            break
        }

        if (sortA > sortB) return 1
        if (sortA < sortB) return -1

        return 0
      })

      // Reverse order if header clicked again
      if (sortDescending) {
        items.reverse()
      }

      return items
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/next-stop/components/map.scss";

.filter {
  gap: 20px;
}

.mgl-map-wrapper {
  height: 40rem;
}
</style>
