<template>
  <div class="map-popup">
    <div class="map-popup__body">
      <div class="map-popup__title">
        <slot name="title" />
      </div>
      <div class="map-popup__subtitle">
        <slot name="subtitle" />
      </div>
      <div class="map-popup__details">
        <slot name="details" />
      </div>
      <div class="map-popup__footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapPopup",
}
</script>

<style lang="scss">
@import "~@core/preset/preset/variables.scss";

.map-popup {
  &__body {
    color: #5d636e;
  }

  &__title {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.5px;
    line-height: 16px;
    color: #5d636e;
    text-transform: uppercase;
    margin-bottom: 5px;
    padding-right: 20px;
    display: flex;
  }

  &__details {
    display: flex;
    flex-direction: column;
  }

  a {
    i {
      font-size: 14px;
      position: relative;
      top: 2px;
    }
  }

  .mapboxgl-popup-close-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
